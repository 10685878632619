import {Config} from "../config";

export async function subscribeToTopic(TOPIC, TOKEN) {
    const DOMAIN_URL = 'https://iid.googleapis.com/iid/v1/';
    await fetch(DOMAIN_URL + TOKEN + '/rel/topics/' + TOPIC, {
        method: 'POST',
        headers: new Headers({
            'Authorization': `key=${Config().firebaseAuthKey}`
        })
    });
}

export async function unsubscribeToTopic(TOPIC, TOKEN) {
    const body = {
        to: "/topics/" + TOPIC,
        registration_tokens: [TOKEN]
    }
    const requestOptions = {
        method: 'POST',
        headers: new Headers({
            'Authorization': `key=${Config().firebaseAuthKey}`
        }),
        body: JSON.stringify(body)
    }
    const DOMAIN_URL = 'https://iid.googleapis.com/iid/v1:batchRemove';
    const response = await fetch(DOMAIN_URL, requestOptions);
    const res = await response.json();
}

