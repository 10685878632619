/* eslint-disable */
import React, {Component} from "react";
import "./home.css";
import {appService} from "../../_services";
import {history} from "../../_helpers/history";
import {userActions} from "../../_actions";
import {connect} from "react-redux";
import SB_logo from "../../silverbird_full.png";
import {requestFirebaseNotification} from "../../Services/firebase";
import {communicationAction} from "../../_actions/communication.actions";
import {Device} from "twilio-client";
import Features from "./features";

import {isSupported, getMessaging, getToken} from 'firebase/messaging'
import {helperFunctions} from "../../_helpers";

//const messaging = firebase.messaging();
class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasEDSSession: false,
            hasH2OSession: false,
            hasCL9Session: false,
            hasELBSession: false,
            edsLogo: "",
            h2oLogo: "",
            cl9Logo: "",
            elbLogo: "",
            showSelectHeading: false,
        };
        this.app = JSON.parse(localStorage.getItem("app"));
        localStorage.removeItem("sms");
        localStorage.removeItem("token");
    }

    componentDidMount = async () => {
        const metaInfo = await appService.checkVersion();
        await helperFunctions.clearCache(metaInfo)
        Device.destroy();
        if (this.app) {
            if (localStorage.getItem("firebaseToken")) {
                if (await isSupported() === true) {
                    let apptTopics = "lctn-appts-" + this.app.locations[0].id;
                    let twillioTopics = "lctn-sms-" + this.app.smsSenderNumber;
                    let statusTopic = "lctn-status-" + this.app.smsSenderNumber;
                    let updateTopic = "app-settings-update";
                    this.unsubscribeTokenToTopic(
                        apptTopics,
                        localStorage.getItem("firebaseToken")
                    );
                    this.unsubscribeTokenToTopic(
                        twillioTopics,
                        localStorage.getItem("firebaseToken")
                    );
                    this.unsubscribeTokenToTopic(
                        statusTopic,
                        localStorage.getItem("firebaseToken")
                    );
                    this.unsubscribeTokenToTopic(
                        updateTopic,
                        localStorage.getItem("firebaseToken")
                    );
                }
            }
            if (await isSupported() === true) {
                await requestFirebaseNotification();
            }
        }

        const edsToken = localStorage.getItem("eterna-day-spa_sessionId");

        if (edsToken != null) {
            this.setState({
                hasEDSSession: true,
                showSelectHeading: true,
            });
        }

        const cl9Token = localStorage.getItem("cloud-9-salon_sessionId");
        if (cl9Token != null) {
            this.setState({
                hasCL9Session: true,
                showSelectHeading: true,
            });
        }

        const h2oToken = localStorage.getItem("salon-h2o_sessionId");

        if (h2oToken != null) {
            this.setState({
                hasH2OSession: true,
                showSelectHeading: true,
            });
        }

        const elbToken = localStorage.getItem("eterna-lash-brow_sessionId");
        if (elbToken != null) {
            this.setState({
                hasELBSession: true,
                showSelectHeading: true,
            });
        }
    }

    openStorePage = async (businessUrl) => {
        const {dispatch} = this.props;
        const storeToken = localStorage.getItem(businessUrl + "_sessionId");
        if (storeToken == null) {
            localStorage.removeItem("user");
            //history.push(`/${businessUrl}/login`);
        } else {
            var user = JSON.parse(localStorage.getItem(businessUrl + "_user"));
            if (user == null) {
                history.push(`/${businessUrl}/login`);
            } else {
                user.token = storeToken;
                user.csrfToken = localStorage.getItem(businessUrl + "_csrf");
                localStorage.setItem(businessUrl + "_user", JSON.stringify(user));
                localStorage.setItem("user", JSON.stringify(user));
                dispatch(userActions.updateAppUser());
                appService.getAppDetails(businessUrl).then(async (res) => {
                    localStorage.removeItem("app");
                    localStorage.setItem("app", JSON.stringify(res));
                    if (await isSupported() === true) {
                        if (isSupported) {
                            if (localStorage.getItem('firebaseToken') !== null) {
                                let apptTopics = "lctn-appts-" + res.locations[0].id;
                                let twillioTopics = "lctn-sms-" + res.smsSenderNumber;
                                let statusTopic = "lctn-status-" + res.smsSenderNumber;
                                let updateTopic = "app-settings-update";
                                this.subscribeTokenToTopic(
                                    apptTopics,
                                    localStorage.getItem("firebaseToken")
                                );
                                this.subscribeTokenToTopic(
                                    twillioTopics,
                                    localStorage.getItem("firebaseToken")
                                );
                                this.subscribeTokenToTopic(
                                    statusTopic,
                                    localStorage.getItem("firebaseToken")
                                );
                                this.subscribeTokenToTopic(
                                    updateTopic,
                                    localStorage.getItem("firebaseToken")
                                );
                            }
                        }
                    }

                    history.push(`/${businessUrl}/`);
                    dispatch(communicationAction.captureInfo(res));
                });
            }
        }
    }

    subscribeTokenToTopic(topic, firebaseToken) {
        fetch(
            "https://iid.googleapis.com/iid/v1/" +
            firebaseToken +
            "/rel/topics/" +
            topic,
            {
                method: "POST",
                headers: new Headers({
                    Authorization:
                        "key=" +
                        "AAAATI00lbI:APA91bEtpdTXNTbQ3gDgJxyS6ILxdWv3Jhkf4eu30sa4G3C2LoYMhrVrBpClTHAGP2E_gf6m6eozT77jtQJud9QOHBAHSwktZCu4_yAvY_qRlPK7-zSTWJ4HMbf-ipwIhdzeegDxdRCr",
                }),
            }
        )
            .then((response) => {
                if (response.status < 200 || response.status >= 400) {
                    throw (
                        "Error subscribing to topic: " +
                        response.status +
                        " - " +
                        response.text()
                    );
                }
                console.log("RES", topic);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    unsubscribeTokenToTopic = (topic, firebaseToken) => {
        const body = {
            to: "/topics/" + topic,
            registration_tokens: [firebaseToken],
        };
        const url = "https://iid.googleapis.com/iid/v1:batchRemove";
        const requestOptions = {
            method: "POST",
            headers: new Headers({
                Authorization:
                    "key=" +
                    "AAAATI00lbI:APA91bEtpdTXNTbQ3gDgJxyS6ILxdWv3Jhkf4eu30sa4G3C2LoYMhrVrBpClTHAGP2E_gf6m6eozT77jtQJud9QOHBAHSwktZCu4_yAvY_qRlPK7-zSTWJ4HMbf-ipwIhdzeegDxdRCr",
            }),
            body: JSON.stringify(body),
        };
        fetch(url, requestOptions)
            .then((response) => {
                if (response.status < 200 || response.status >= 400) {
                    throw (
                        "Error subscribing to topic: " +
                        response.status +
                        " - " +
                        response.text()
                    );
                }
                console.log("un", topic);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    render() {
        const {
            showSelectHeading,
            hasEDSSession,
            hasCL9Session,
            hasH2OSession,
            hasELBSession,
        } = this.state;
        return (
            <section id="home-page-wrapper" className="home-page session-home">
                <div className="home-page-sidebar session-page">
                    <div className="culry-logo mobile-hidden">
                        <img src={SB_logo} alt="logo"/>
                    </div>

                    <Features/>

                    <div className="inquery">
                        <p>
                            For inquiries, please contact{" "}
                            <a className="text-warning" href="mailto:info@zoracorp.com">
                                info@zoracorp.com
                            </a>
                        </p>
                    </div>
                </div>
                <div className="login-box">
                    <div className="white-box d-table">
                        <div className="d-table-cell">
                            <div className="culry-logo desktop-hidden">
                                <img src={SB_logo} alt="logo"/>
                            </div>
                            <h3 className="desktop-hidden">
                                Welcome to Silverbird <br/>
                                The ultimate salon app
                            </h3>
                            <div className="session">
                                {showSelectHeading == true && (
                                    <div className="with-session">
                                        <div className="text-center">
                                            <h3 className="custom-info">Please Select Your Store</h3>
                                        </div>
                                    </div>
                                )}
                                {showSelectHeading == false && (
                                    <div className="without-session">
                                        <div className="text-center">
                                            <h3 className="text-warning">
                                                Please contact your manager for your Salon's homepage
                                                link
                                            </h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="has-session text-center">
                                <div className="site-wrapper">
                                    {hasEDSSession == true && (
                                        <div className="site">
                                            <a onClick={() => this.openStorePage("eterna-day-spa")}>
                                                <img
                                                    className="home-logos"
                                                    src="https://s3.amazonaws.com/zoracorp/bianca/472822496/images/meta/logo/et_n.jpg"
                                                />
                                            </a>
                                        </div>
                                    )}

                                    {hasCL9Session == true && (
                                        <div className="site">
                                            <a onClick={() => this.openStorePage("cloud-9-salon")}>
                                                <img
                                                    className="home-logos"
                                                    src="https://s3.amazonaws.com/zoracorp/bianca/123456789/images/meta/logo/cloud.jpg"
                                                />
                                            </a>
                                        </div>
                                    )}

                                    {hasH2OSession == true && (
                                        <div className="site">
                                            <a onClick={() => this.openStorePage("salon-h2o")}>
                                                <img
                                                    className="home-logos"
                                                    src="https://s3.amazonaws.com/zoracorp/bianca/234567890/images/meta/logo/salon-h2o.jpg"
                                                />
                                            </a>
                                        </div>
                                    )}
                                    {hasELBSession == true && (
                                        <div className="site">
                                            <a onClick={() => this.openStorePage("eterna-lash-brow")}>
                                                <img
                                                    className="home-logos"
                                                    src="https://zoracorp.s3.amazonaws.com/bianca/345678901/images/meta/logo/elbb.jpg"
                                                />
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right">
                        <p>{new Date().getFullYear()} &copy; Powered by ZorA</p>
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state.authentication;
    return {
        user,
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export {connectedHomePage as HomePage};
